import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div className="conteudo_geral">
          <noscript
            aria-hidden="true"
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQDRJ93" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>'
            }}
          />
          {/* <script
            dangerouslySetInnerHTML={{
              __html:
                "\n// **** Scroll na pag 'como-funciona' : ****\njQuery(window).on('load', function() {\nvar numberSlides = 3;\t\t//**** Alterar este nr sempre que for necessario incluir novas div com conteudo nesta pagina especifica; corresponde ao nr total de divs\nvar distance = 500;\nvar timing = 750;\njQuery('#div_pageNr').html('1');\njQuery('#div_setacima').hide();\njQuery('#div_setacima').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) - 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >= numberSlides ){\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\njQuery('#div_setabaixo').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) + 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >=2){\t\t\t//**** Alterar este nr sempre que for necessario ajustar o nr de divs com conteudo a mostrar nesta pagina especifica\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\n});\n"
            }}
          /> */}
          <div id="head_geral" className="container">
            <div className="container">
              <header id="head" role="banner">
                <div className="five columns alpha">
                  <div className="bisnaga" />
                </div>
                <div className="menu_linha_cima" />
                <div className="menu_linha_abaixo" />
                <div
                  className="eleven columns omega"
                  style={{
                    float: "right"
                  }}
                >
                  <div className="cabecalho_esq">
                    <div className="div_logo">
                      <a href="/" title="Início">
                        <img
                          className="img_logo"
                          src="/sites/default/files/color/responsive/logo.png"
                          alt="Início"
                        />
                      </a>{" "}
                    </div>
                  </div>
                  <nav id="navigation" role="navigation">
                    <div id="main-menu">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/o-que-e-biafine">O que é biafine<sup>®</sup></a>
                        </li>
                        <li className="leaf">
                          <a href="/quando-e-como-aplicar">
                            Quando e como <br /> aplicar
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/como-funciona">Como funciona</a>
                        </li>
                        <li className="leaf">
                          <a href="/balsamo-multi-reparador-apaziguante"><span className="blue-text">Cica</span> biafine<sup>®</sup> <br /><span className="align-leaf">Cosmético</span></a>
                        </li>
                        <li className="last leaf">
                          <a href="/contactos">Contactos</a>
                        </li>
                      </ul>{" "}
                    </div>
                  </nav>
                </div>
              </header>
            </div>
          </div>
          <div className="container" id="content-contain">
            <div className="menu_shadow" />
            <div id="content" className="sixteen columns">
              <div id="breadcrumbs">
                <h2 className="element-invisible">Está aqui</h2>
                <nav className="breadcrumb">
                  <a href="/">Início</a> » Queimaduras de 1º grau
                </nav>
              </div>
              <section id="post-content" role="main">
                <h1 className="page-title">Queimaduras de 1º grau</h1>
                
                <div class="slide-list-flex">

                  <div class="flex-div">
                    <div class="number-list">1</div>
                    <div className="list-left">
                      <img src="/sites/default/files/1grau_2_0.png" />
                    </div>
                    <div className="list-right">
                      <p> Aplicar BIAFINE<sup>®</sup> em camada
                        espessa, ultrapassando largamente 
                        a superfície da ferida ou lesão.</p>
                    </div>
                  </div>
                </div>
                <div class="slide-list-flex">

                  <div class="flex-div">
                    <div class="number-list">2</div>
                    <div className="list-left">
                      <img src="/sites/default/files/1grau_3_0.png" />
                    </div>
                    <div className="list-right">
                      <p> Fazer penetrar a última camada de
                        BIAFINE<sup>®</sup> através de ligeira massagem.</p>
                    </div>
                  </div>
                </div>
                <div class="slide-list-flex">

                  <div class="flex-div">
                    <div class="number-list">3</div>
                    <div className="list-left">
                      <img src="/sites/default/files/1grau_2.png" />
                    </div>
                    <div className="list-right">
                      <p> Continuar a aplicar BIAFINE<sup>®</sup>
                        como descrito anteriormente, 2 a 4 vezes ao dia,
                        até que a área afetada cure completamente.</p>
                    </div>
                  </div>
                </div>
              </section>
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html:
                    "\njQuery(window).on('load', function () {\njQuery('.BlocoPagInt1-textoVerdeClaro').each(function() {\nvar url = jQuery(this).attr('href');\n});\njQuery('.view-bloco-pag-interior1 .views-row-2 .views-field-field-imagem-bloco-pag-interior1').addClass('bloco_activo');\n});\n"
                }}
              />
            </div>
            <div className="clear" />
          </div>
          <div id="bloco_geral_pag_interior1" className="container">
            <div id="bloco_pag_interior1" className="container">
              <div
                id="block_qd_e_como_aplicar"
                className="sidebar five columns"
              >
                <div className="region region-block-pag-interior1">
                  <div
                    id="block-views-bloco-pag-interior1-block"
                    className="block block-views"
                  >
                    <div className="content">
                      <div className="view view-bloco-pag-interior1 view-id-bloco_pag_interior1 view-display-id-block view-dom-id-e75582f5d89c8bfe6209ac5f2a50f27d">
                        <div className="view-content">
                          <a href="/queimaduras-do-1-grau">
                            <div className="views-row views-row-1 views-row-odd views-row-first blocos-paginterior1">
                              <div className="views-field views-field-field-imagem-bloco-pag-interior1">
                                {" "}
                                <div className="field-content img_textos2">
                                  <img
                                    typeof="foaf:Image"
                                    src="/sites/default/files/img_pag_como_funciona-bloco-1-v3_0.png"
                                    width="259"
                                    height="150"
                                    alt
                                  />
                                </div>
                              </div>
                              <div className="views-field views-field-field-url-bloco-pag-interior1">
                                {" "}
                                <div className="field-content">
                                  <a
                                    href="/queimaduras-do-1-grau"
                                    className="BlocoPagInt1-textoVerdeClaro"
                                  >
                                    Queimaduras de 1º grau
                                  </a>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="/feridas-cutaneas">
                            <div className="views-row views-row-2 views-row-even blocos-paginterior1">
                              <div className="views-field views-field-field-imagem-bloco-pag-interior1">
                                {" "}
                                <div className="field-content img_textos2">
                                  <img
                                    typeof="foaf:Image"
                                    src="/sites/default/files/img_pag_como_funciona-bloco-2-259x150.png"
                                    width="259"
                                    height="150"
                                    alt
                                  />
                                </div>
                              </div>
                              <div className="views-field views-field-field-url-bloco-pag-interior1">
                                {" "}
                                <div className="field-content">
                                  <a
                                    href="/feridas-cutaneas"
                                    className="BlocoPagInt1-textoVerdeClaro"
                                  >
                                    Feridas cutâneas
                                  </a>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="/eritrema-solar">
                            <div className="views-row views-row-3 views-row-odd views-row-last blocos-paginterior1">
                              <div className="views-field views-field-field-imagem-bloco-pag-interior1">
                                {" "}
                                <div className="field-content img_textos2">
                                  <img
                                    typeof="foaf:Image"
                                    src="/sites/default/files/img_pag_como_funciona-bloco-4-259x150_0.png"
                                    width="259"
                                    height="149"
                                    alt
                                  />
                                </div>
                              </div>
                              <div className="views-field views-field-field-url-bloco-pag-interior1">
                                {" "}
                                <div className="field-content">
                                  <a
                                    href="/eritrema-solar"
                                    className="BlocoPagInt1-textoVerdeClaro"
                                  >
                                    Eritema solar
                                  </a>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div_branca" />
          </div>
          <div id="copyright_geral" className="container">
            <div id="copyright" className="container">
              <div className="background_img_interior" />
              <div className="rodape_esq">
                <div className="region region-footer">
                  <div id="block-block-1" className="block block-block">
                    <div className="content">
                      <p>
                        www.biafine.pt © JNTL Consumer Health (Portugal) Limitada, 2024.                        
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodapeDir rodape_dir">
                <div className="region region-footer-menu">
                  <div
                    id="block-menu-menu-menu-rodape"
                    className="block block-menu"
                  >
                    <div className="content">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/politica-de-privacidade" title>
                            POLÍTICA DE PRIVACIDADE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/cookie-policy" title>
                            POLÍTICA DE COOKIES
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/aviso-legal" title>
                            AVISO LEGAL
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/mapa-do-site" title>
                            MAPA DO SITE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/contactos" title>
                            CONTACTOS
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodape_centro">
                <div className="region region-footer-center">
                  <div id="block-block-4" className="block block-block">
                    <div className="content">
                      <div>
                        Este site é publicado pela JNTL Consumer Health (Portugal) Limitada única responsável pelo seu
                        conteúdo.&nbsp;Destina-se a utilizadores em Portugal.
                      </div>
                      <div>
                        V08 <b>Biafine<sup>®</sup></b>, trolamina. Medicamento indicado em queimaduras de 1º grau, eritema solar e feridas
                        superficiais não infetadas. Afastar dos olhos. Contém excipientes que podem originar reações
                        cutâneas/alérgicas. Contraindicado em caso de hipersensibilidade aos componentes. Leia
                        cuidadosamente o folheto informativo. Em caso de dúvida ou de persistência dos sintomas consulte o
                        seu médico ou farmacêutico. JNTL Consumer Health (Portugal), Lda. PT-BIA-2400001. 04/2024
                        <br />
                        &nbsp;
                      </div>
                      <div>&nbsp;</div>
                      <div className="last leaf">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Configuração de cookies
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
